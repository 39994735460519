/**
 * Middleware to redirect authenticated users to the home page
 * use case: /auth page
 */
export default defineNuxtRouteMiddleware(async (_to, _from) => {
    const user = useSupabaseUser()

    if (user.value && !user.value.is_anonymous) {
        return navigateTo(Urls.Home())
    }
})
